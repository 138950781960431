<template>
  <span class="font-bold">{{ t("customer.documents.header") }} </span>
  <div class="c-customer-document-upload">
    <div class="col-12 md:col-12 lg:col-12 p-0">
      <DocumentUploader
        :documents="documents"
        @documentUploaded="addDocument($event)"
        @clearDocumentsClicked="clearDocuments"
        @keydown="handleKeydown"
      />
      <DocumentCarousel
        v-if="documents.length > 0"
        :documents="documents"
        @updateDocument="updateDocument"
        @deleteDocument="removeDocument"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

import { nextTick } from "vue";
import { CustomerDocument } from "@/models/CustomerDocument";
import DocumentUploader from "./DocumentUploader.vue";
import DocumentCarousel from "./DocumentCarousel.vue";
import { useConfirm } from "primevue/useconfirm";

const { t } = useI18n();

const confirm = useConfirm();
const documentsToDelete: CustomerDocument[] = [];

const documents = defineModel<CustomerDocument[]>("documents", {
  required: true,
});
const uploadOnKeyPress = () => {
  const uploadButton = document.getElementById("upload-btn");
  if (uploadButton) {
    uploadButton.focus();
    uploadButton.click();
  }
};

const handleKeydown = (event: KeyboardEvent) => {
  const elementBtnComit = document.getElementById("btn-commit") as HTMLButtonElement;
  const elementBtnCancel = document.getElementById("btn-cancel");
  const elemebtBtnClear = document.getElementById("btn-clear-documents") as HTMLButtonElement;
  const elementBtnContact = document.getElementById("add-contact-button");

  if (event.key === "Enter") {
    event.preventDefault();
    if (documents.length > 0) {
      const titleInput = document.getElementById("document-title-input");
      if (titleInput) {
        titleInput.focus();
      }
    } else {
      uploadOnKeyPress();
    }
  }
  if (event.key === "Tab" && event.shiftKey === false && elemebtBtnClear?.disabled && elementBtnContact === null) {
    nextTick(() => (elementBtnComit?.disabled ? elementBtnCancel?.focus() : elementBtnComit?.focus()));
    event.preventDefault();
  }
};

const addDocument = (document: CustomerDocument) => {
  documents.value.push(document);
};

const removeDocument = async (url: string) => {
  const index = documents.value.findIndex((doc) => doc.documentUrl === url);
  if (index !== -1) {
    documentsToDelete.push(documents.value[index]);
    documents.value.splice(index, 1);
  }
  await nextTick();
  document.getElementById("upload-wrapper-button")?.focus();
};

const updateDocument = (index: number, updateDocument: CustomerDocument) => {
  documents.value[index] = updateDocument;
};

const clearDocuments = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm-all"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      documents.value = [];
    },
  });
};
</script>
