<template>
  <FloatLabel variant="on">
    <InputNumber
      id="credit-limit"
      v-model="creditLimitComputed"
      data-testid="credit-limit"
      mode="decimal"
      :min="0"
      class="w-full mr-8"
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('customer.credit-limit').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="credit-limit">
      {{ t(`customer.credit-limit`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  creditLimit: number;
}>();

const emit = defineEmits<{
  (e: "update:creditLimit", value: number): void;
}>();
const creditLimitComputed = computed<number>({
  get: () => {
    return props.creditLimit ?? 0;
  },
  set: (value) => {
    emit("update:creditLimit", value);
  },
});
</script>
