<template>
  <FloatLabelInput
    :label="t('customer.email')"
    v-model:value="emailComputed"
    id="customer-email"
    dataTestId="customer-email"
    :maxlength="500"
    :isRequired="true"
    :isInvalid="val.$error"
    :focusInput="focusInput"
  />
  <small class="p-error ml-6" v-if="val.$error" data-testid="customer-email-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  emailAddress: string;
  focusInput?: boolean;
}>();

const emit = defineEmits<{
  (e: "update:emailAddress", value: string): void;
}>();

const { t } = useI18n();

const emailComputed = computed<string>({
  get: () => {
    return props.emailAddress ?? "";
  },
  set: (value) => {
    emit("update:emailAddress", value);
  },
});

const rules = {
  emailAddress: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
