import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { CustomerSearchRequest } from "./model/CustomerSearchRequest";
import { CustomerSearchResponse } from "./model/CustomerSearchResponse";
import { useErrorHandler } from "../ErrorHandler";
import { ref } from "vue";

export function useSearch() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const customerRequest = ref<CustomerSearchRequest | null>(null);
  const customerResponse = ref<CustomerSearchResponse | null>(null);

  const loading = ref(false);

  const customerSearch = async (
    request: CustomerSearchRequest,
    clearCache = false
  ): Promise<CustomerSearchResponse> => {
    try {
      loading.value = true;

      if (clearCache) {
        customerRequest.value = null;
        customerResponse.value = null;
      }
      const authHeaders = await getAuthHeaders();
      if (customerRequest.value && customerRequest.value?.equals(request) && customerResponse.value !== null) {
        return customerResponse.value;
      }

      customerResponse.value = await searchApi.customerSearch(authHeaders, request);
      customerRequest.value = request;
      return customerResponse.value;
    } catch (error) {
      await handleError(error);
      return new CustomerSearchResponse();
    }
  };

  return { customerSearch };
}
