import { useAuth } from "@cumulus/event-bus";
import { customerApi } from "./CustomerApi";
import { Customer } from "@/models/customer/Customer";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { NewCustomer } from "@/models/customer/NewCustomer";
import { CustomerType } from "@/models/customer/CustomerType";
import { BusinessCustomer } from "@/models/customer/BusinessCustomer";
import { PrivateCustomer } from "@/models/customer/PrivateCustomer";
import cloneDeep from "lodash.clonedeep";

export function useCustomer() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCustomer = async (id: string): Promise<Customer> => {
    try {
      const authHeaders = await getAuthHeaders();
      const customer = await customerApi.get(authHeaders, id);
      if (customer.businessCustomer === null) {
        customer.businessCustomer = new BusinessCustomer();
      }
      if (customer.privateCustomer === null) {
        customer.privateCustomer = new PrivateCustomer();
      }
      return customer;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createCustomer = async (customer: NewCustomer): Promise<void> => {
    try {
      const newCustomer = cloneDeep(customer);
      if (newCustomer.customerType === CustomerType.B2B) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newCustomer.privateCustomer = null;
      } else if (newCustomer.customerType === CustomerType.B2C) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newCustomer.businessCustomer = null;
      }
      const authHeaders = await getAuthHeaders();
      return await customerApi.create(authHeaders, newCustomer);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCustomer = async (customer: Customer): Promise<void> => {
    try {
      const updateCustomer = cloneDeep(customer);
      if (updateCustomer.customerType === CustomerType.B2B) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateCustomer.privateCustomer = null;
      } else if (updateCustomer.customerType === CustomerType.B2C) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateCustomer.businessCustomer = null;
      }
      const authHeaders = await getAuthHeaders();
      return await customerApi.update(authHeaders, updateCustomer);
    } catch (error) {
      await handleError(error);
    }
  };

  const deleteCustomer = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await customerApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
    }
  };

  const checkCustomerNumberUsed = async (customerNumber: string): Promise<boolean> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await customerApi.checkCustomerNumberUsed(authHeaders, customerNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getCustomer, createCustomer, updateCustomer, deleteCustomer, checkCustomerNumberUsed };
}
