<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <Select
        id="customer-document-language"
        :options="transformedDocumentLanguages"
        optionLabel="name"
        optionValue="value"
        data-testid="customer-document-language"
        v-model="selectedDocumentLanguage"
        class="w-full"
        :fluid="true"
        :isInvalid="val.documentLanguage.$error"
        :isRequired="true"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('customer.document-language').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />

      <label for="customer-document-language">
        {{ t(`customer.document-language`) }}
      </label>
    </FloatLabel>
    <small
      id="customer-document-language-help"
      data-testid="customer-document-language-error"
      class="p-error ml-6"
      v-if="val.documentLanguage.$error"
      >{{ val.documentLanguage.$errors[0].$message }}</small
    >
  </div>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { documentLanguageApi } from "@/repositories/document-language/DocumentLanguageApi";
import { type DocumentLanguage } from "@/repositories/document-language/model/DocumentLanguage";
import { useVuelidate } from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  documentLanguage: string;
}>();

const emit = defineEmits<{
  (e: "update:documentLanguage", value: string): void;
}>();

const selectedDocumentLanguage = computed<string>({
  get: () => {
    return props.documentLanguage ?? "";
  },
  set: (value) => {
    emit("update:documentLanguage", value);
  },
});

const documentLanguages = ref<DocumentLanguage[]>([]);
const fetchDocumentLanguages = async () => {
  const response = await documentLanguageApi.getAll();
  documentLanguages.value = response.map((languageId: string): DocumentLanguage => {
    return {
      id: languageId,
      name: t(`common.document-language.${languageId}`),
    };
  });
};
onMounted(fetchDocumentLanguages);

const rules = {
  documentLanguage: {
    required,
  },
};

const val = useVuelidate(rules, props);
const transformedDocumentLanguages = computed(() =>
  documentLanguages.value.map((language) => ({
    value: language.id,
    name: language.name,
  })),
);
</script>
