import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const customerRoutes: RouteRecordRaw[] = [
  {
    name: "customer-search",
    path: "/customer/search/:query*",
    component: (): Component => import("@/views/CustomerSearch.vue"),
    meta: {
      authRequired: true,
      title: "customer.search-customer",
    },
  },
  {
    name: "customer-add",
    path: "/customer/add",
    component: (): Component => import("@/views/CustomerAdd.vue"),
    meta: {
      authRequired: true,
      title: "customer.add-customer",
    },
  },
  {
    name: "customer-edit",
    path: "/customer/edit/:id",
    component: (): Component => import("@/views/CustomerEdit.vue"),
    meta: {
      authRequired: true,
      title: "customer.edit-customer",
    },
  },
];

export const mainRoutes: RouteRecordRaw[] =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Routes",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/b2c/OAuth2Redirect.vue"),
        },
        {
          name: "logout",
          path: "/logout",
          component: (): Component => import("../components/b2c/Logout.vue"),
        },
        {
          name: "Failed",
          path: "/failed",
          component: (): Component => import("../components/b2c/Failed.vue"),
        },
      ]
    : [];
