<template>
  <div class="field">
    <FloatLabelInput
      :label="addressLabel"
      v-model:value="addressLine1Computed"
      :isRequired="true"
      :isInvalid="val.addressLines.$error"
      :dataTestId="`${addressType}-address-line-1`"
    />
    <FloatLabelInput :label="''" v-model:value="addressLine2Computed" :dataTestId="`${addressType}-address-line-2`" />
    <FloatLabelInput :label="''" v-model:value="addressLine3Computed" :dataTestId="`${addressType}-address-line-3`" />
  </div>
  <small
    class="p-error ml-6"
    v-if="val.addressLines.$error"
    :id="`${addressType}-address-lines-error`"
    :data-testid="`${addressType}-address-lines-error`"
  >
    {{ val.addressLines.$errors[0].$message }}
  </small>
  <div class="mt-6">
    <FloatLabelDropdown
      :id="`${addressType}-address-country`"
      :dataTestId="`${addressType}-country`"
      :label="t('common.address.country')"
      :placeholder="t('placeholder.type', { property: t('common.address.country').toLowerCase() })"
      :isRequired="true"
      :isInvalid="val.countryIso.$error"
      v-model:value="selectedCountryComputed"
      :options="transformedCountryOptions"
      :isFloatingLabel="isFloatingLabel"
    />
    <small class="p-error ml-6" v-if="val.countryIso.$error" :id="`${addressType}-address-country-error`">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>
  <div class="field">
    <div class="col p-0">
      <div class="formgrid grid grid-cols-12 gap-4 mt-4">
        <div class="field col-span-12 md:col-span-4">
          <FloatLabelInput
            :id="`${addressType}-postal-code`"
            :dataTestId="`${addressType}-postal-code`"
            :maxlength="4"
            :isInvalid="val.postalCode.$error"
            :isRequired="true"
            :placeholder="t('placeholder.type', { property: t('common.address.postal-code').toLowerCase() })"
            :label="t('common.address.postal-code')"
            v-model:value="addressComputed.postalCode"
            @blur="onPostalCodeChange"
          />
          <small
            class="p-error ml-6"
            v-if="val.postalCode.$error"
            :id="`${addressType}-postal-code-error`"
            :data-testid="`${addressType}-postal-code-error`"
            >{{ val.postalCode.$errors[0].$message }}</small
          >
        </div>
        <div class="field col-span-12 md:col-span-8">
          <FloatLabelInput
            :id="`${addressType}-city`"
            :dataTestId="`${addressType}-city`"
            :label="t('common.address.city')"
            :placeholder="t('placeholder.type', { property: t('common.address.city').toLowerCase() })"
            :isInvalid="val.city.$error"
            :isRequired="true"
            v-model:value="addressComputed.city"
            @blur="onCityChanged"
          />
          <small
            class="p-error ml-6"
            v-if="val.city.$error"
            :id="`${addressType}-city-error`"
            :data-testid="`${addressType}-city-error`"
            >{{ val.city.$errors[0].$message }}</small
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Address } from "@/models/customer/Address";
import { Country } from "@/models/country/Country";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { useCountry } from "@/api/country/CountryService";
import { helpers } from "@vuelidate/validators";
import cloneDeep from "lodash.clonedeep";

const { t } = useI18n();
const { getCity } = useCountry();

const props = defineProps<{
  address: Address;
  countryOptions: Country[];
  addressType: string;
  selectedCountry: string;
  addressLabel?: string;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: Address): void;
  (e: "autoSelectCountry", value: string): void;
}>();

const addressComputed = computed<Address>(() => {
  return props.address ? cloneDeep(props.address) : new Address();
});
const addressLine1Computed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines[0];
  },
  set: (val) => {
    addressComputed.value.addressLines[0] = val;
    onChangeComplete();
  },
});
const addressLine2Computed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines[1];
  },
  set: (val) => {
    addressComputed.value.addressLines[1] = val;
    onChangeComplete();
  },
});
const addressLine3Computed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines[2];
  },
  set: (val) => {
    addressComputed.value.addressLines[2] = val;
    onChangeComplete();
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    if (props.selectedCountry) {
      emit("autoSelectCountry", props.selectedCountry);
    }
    return addressComputed.value.countryIso;
  },
  set: (value: string) => {
    addressComputed.value.countryIso = value;
    onChangeComplete();
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }

  const response = await getCity(code);

  if (response.length > 0) {
    addressComputed.value.city = response[0].city;
  }

  onChangeComplete();
};

const onCityChanged = (city: string) => {
  addressComputed.value.city = city;
  onChangeComplete();
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines.error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, addressComputed);

const transformedCountryOptions = computed(() =>
  props.countryOptions.map((country) => ({
    value: country.iso,
    name: country.name,
  }))
);

const isFloatingLabel = computed(() => {
  return selectedCountryComputed.value !== "";
});
</script>
