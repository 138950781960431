<template>
  <FloatLabel variant="on">
    <Select
      id="customer-freight-method"
      data-testid="default-freight-method"
      :options="transformedFreightMethods"
      v-model="selectedFreightMethodId"
      class="mr-8"
      fluid
      optionLabel="name"
      optionValue="value"
      pt:list:data-testid="customer-freight-method-list"
      v-tooltip.bottom="{
        value: t('placeholder.select', { property: t('customer.freight-method').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />

    <label for="customer-freight-method">
      {{ t(`customer.freight-method`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { type FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const props = defineProps<{
  defaultFreightMethod: string;
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (e: "update:defaultFreightMethod", value: string): void;
}>();

const selectedFreightMethodId = computed<string>({
  get: () => {
    return props.defaultFreightMethod ?? "";
  },
  set: (value) => {
    emit("update:defaultFreightMethod", value);
  },
});

const transformedFreightMethods = computed(() =>
  props.freightMethods.map((method) => ({
    value: method.id,
    name: method.name,
  })),
);
</script>
