import { AuthHeaders, httpClient } from "@cumulus/http";
import { Client } from "@/models/client/Client";

class ClientApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != "undefined" && import.meta.env.VITE_APP_API_COMPANY
        ? import.meta.env.VITE_APP_API_COMPANY + "/clients"
        : `${import.meta.env.VITE_APP_API_URL as string}/clients`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Client[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then((response) => response.data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Client> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }
}

const clientApi = new ClientApi();
export { clientApi };
