<template>
  <div class="mt-4">
    <FloatLabelDropdown
      :label="t('customer.payment-term')"
      :placeholder="t('placeholder.select', { property: t('customer.payment-term').toLowerCase() })"
      dataTestId="default-payment-term"
      :isInvalid="val.paymentTermId.$error"
      :isRequired="true"
      v-model:value="paymentTermComputed"
      :options="tranformedPaymentTerms"
      :isFloatingLabel="isFloatingLabel"
    />
  </div>
  <small class="p-error ml-6" v-if="val.$error" data-testid="default-payment-term-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";

import { PaymentTerm } from "@/repositories/payment-term/model/PaymentTerm";
import { nonEmptyGuid } from "@/locales/i18n-validators";
import { usePaymentTerm } from "@/repositories/payment-term/PaymentTermService";
import { NIL as emptyUuid } from "uuid";

const { getAllPaymentTerms } = usePaymentTerm();

const props = defineProps<{
  paymentTermId: string;
}>();

const emit = defineEmits<{
  (e: "update:paymentTermId", value: string): void;
}>();

const paymentTermComputed = computed<string>({
  get: () => {
    return props.paymentTermId ?? "";
  },
  set: (value) => {
    emit("update:paymentTermId", value);
  },
});

const paymentTerms = ref<PaymentTerm[]>([]);

const getPaymentTerms = async () => {
  paymentTerms.value = await getAllPaymentTerms();
};
const tranformedPaymentTerms = computed(() =>
  paymentTerms.value.map((paymentTerm) => ({
    value: paymentTerm.id,
    name: paymentTerm.name,
  }))
);
const isFloatingLabel = computed(() => {
  return props.paymentTermId !== emptyUuid;
});

onMounted(getPaymentTerms);

const rules = {
  paymentTermId: {
    nonEmptyGuid,
    required,
  },
};

const val = useVuelidate(rules, props);
const { t } = useI18n();
</script>
