import { httpClient, AuthHeaders } from "@cumulus/http";
import { Customer } from "@/models/customer/Customer";
import { NewCustomer } from "@/models/customer/NewCustomer";
import { CustomerType } from "@/models/customer/CustomerType";

class CustomerApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != "undefined" && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER + "/customers"
        : `${import.meta.env.VITE_APP_API_URL as string}/customers`;
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Customer> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then((response) => response.data);
  }

  public async checkCustomerNumberUsed(authHeaders: AuthHeaders, customerNumber: string): Promise<boolean> {
    return await httpClient(this.uri, authHeaders)
      .get(`/numbers/isused/${customerNumber}`)
      .then((response) => response.data);
  }

  public async create(authHeaders: AuthHeaders, customer: NewCustomer): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", customer);
  }

  public async update(authHeaders: AuthHeaders, customer: Customer): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", customer);
  }

  public async delete(authHeaders: AuthHeaders, id: string): Promise<void> {
    return await httpClient(this.uri, authHeaders).delete(id);
  }

  public async getTemplateProperties(authHeaders: AuthHeaders, customerType: CustomerType): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get(`/import-file?customerType=${customerType}`)
      .then(({ data }) => data);
  }

  public async importFile(authHeaders: AuthHeaders, customerType: CustomerType, formData: FormData): Promise<void> {
    return await httpClient(this.uri, authHeaders).post(`/import-file?customerType=${customerType}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const customerApi = new CustomerApi();
export { customerApi };
