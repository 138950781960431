<template>
  <div>
    <DataTable
      ref="customerSearchResult"
      :value="customers"
      v-model:expandedRows="expandedRows"
      :autoLayout="true"
      dataKey="id"
      selectionMode="single"
      responsiveLayout="scroll"
      @row-select="onRowSelected"
      @row-dblclick="onRowDoubleClick"
      v-model:selection="selectedCustomer"
      :loading="loading"
      :sortOrder="sortOrder"
      :sortField="sortField"
      class="c-datatable"
      :lazy="true"
      :paginator="true"
      :totalRecords="totalHits"
      :stripedRows="true"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowClass="addGlobalSearchClass"
      @sort="onSort"
      @page="onPage"
      tabindex="0"
      data-testid="customer-list"
      scrollable
      :scrollHeight="'calc(100vh - 225px)'"
      :resizableColumns="true"
      columnResizeMode="fit"
      removableSort
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="t(col.header)"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field }">
          <template v-if="col.field === CustomerLinesColumns.RegisteredDate">
            <div class="flex justify-between items-center">
              <span>{{ d(data.registeredDate, "short") }}</span>
            </div>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.CustomerNumber">
            <span class="c-customer-number-text" @click="onCustomerNumberClicked(data)">
              {{ data.customerNumber }}
            </span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.CustomerType">
            <span>{{ data.customerType }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.Name">
            <span>{{ data.name }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.PhoneNumber">
            <span>{{ data.phoneNumber }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.Email">
            <span>{{ data.email }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.DeliveryAddressLine1">
            <span>{{ data.deliveryAddressLine1 }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.DeliveryAddressCity">
            <span>{{ data.deliveryAddressCity }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.AddressCountry">
            <span>{{ data.addressCountry }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.AddressLine1">
            <span>{{ data.addressLine1 }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.AddressCity">
            <span>{{ data.addressCity }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.FirstContactName">
            <span>{{ data.firstContactName }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.FirstContactEmail">
            <span>{{ data.firstContactEmail }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.FirstContactPhone">
            <span>{{ data.firstContactPhone }}</span>
          </template>
          <template v-else-if="col.field === CustomerLinesColumns.CustomerGroups">
            <span>{{ data.customerGroups }}</span>
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>

      <template #empty>
        <div v-if="!loading">{{ t("customer.search.empty-list") }}</div>
      </template>
      <template #loading>{{ t("customer.search.loading-list") }}</template>
    </DataTable>

    <ColumnManager
      ref="customerListSortRef"
      :searchColumns="customerSearchColumns"
      :defaultSortField="defaultSortField"
      v-model:selectedIcon="selectedIcon"
      v-model:selectedColumnsComputed="selectedColumnsComputed"
      v-model:filteredColumns="filteredColumns"
      v-model:selectAll="selectAll"
      @onSelectedRow="onSelectedRow"
      @onSelectAllChange="onSelectAllChange"
      @onColumnSelected="onColumnSelected"
      @onColumnUnselected="onColumnUnselected"
      @resetColumns="resetColumns"
    />
  </div>
</template>
<script setup lang="ts">
import { computed, nextTick, ref, watch } from "vue";
import { useRouter, useRoute, type LocationQueryValue } from "vue-router";
import { useToast } from "primevue/usetoast";
import { SearchCustomer } from "@/models/search/customer/SearchCustomer";
import type {
  DataTablePageEvent,
  DataTableRowDoubleClickEvent,
  DataTableRowSelectEvent,
  DataTableSelectAllChangeEvent,
  DataTableSortEvent,
} from "primevue/datatable";
import { useI18n } from "vue-i18n";
import { useCumulusToast } from "@cumulus/toast";
import { NIL as emptyUuid } from "uuid";
import { ListCustomer } from "@/models/search/customer/ListCustomer";
import { CustomerLinesColumns } from "@/models/search/customer/CustomerLinesColumns";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import ColumnManager from "@/components/ColumnManager.vue";

const { t, d } = useI18n();

const router = useRouter();
const route = useRoute();

const toast = useCumulusToast(useToast());
const selectedCustomer = ref<SearchCustomer>(new SearchCustomer());
const expandedRows = ref([]);
const customerSearchResult = ref();

const props = defineProps<{
  customers: ListCustomer[];
  loading: boolean;
  totalHits: number;
  pageSize: number;
  page: number;
  sortOrder: number;
  sortField: string;
}>();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
  (e: "update:sort", value: string): void;
  (e: "selectedIcon", value: string): void;
}>();

const customerListSortRef = ref();
const openColumnChooser = (event: Event) => {
  customerListSortRef.value.openColumnChooser(event);
};

const openSortList = (event: Event) => {
  customerListSortRef.value.openSortList(event);
};

defineExpose({
  openColumnChooser,
  openSortList,
});

const customerSearchColumns = [
  { field: "registeredDate", header: t("customer.created-date"), sortable: true },
  { field: "customerNumber", header: t("customer.customer-number.label"), sortable: true },
  { field: "customerType", header: t("customer.search.header-business-customer"), sortable: true },
  { field: "name", header: t("customer.search.header-name"), sortable: true },
  { field: "phoneNumber", header: t("customer.search.header-phone"), sortable: true },
  { field: "email", header: t("customer.search.header-email"), sortable: true },
  {
    field: "deliveryAddressLine1",
    header: t("customer.search.header-delivery-address"),
    sortable: true,
  },
  { field: "deliveryAddressCity", header: t("customer.search.header-delivery-city"), sortable: true },
  { field: "addressCountry", header: t("customer.search.header-country"), sortable: true, class: "w-4" },
  { field: "addressLine1", header: t("customer.search.header-address"), sortable: true },
  { field: "addressCity", header: t("customer.search.header-city"), sortable: true },
  { field: "firstContactName", header: t("customer.search.header-contact-name"), sortable: true },
  { field: "firstContactEmail", header: t("customer.search.header-contact-email"), sortable: true },
  { field: "firstContactPhone", header: t("customer.search.header-contact-phone"), sortable: true },
  { field: "customerGroups", header: t("customer.search.header-groups"), sortable: true },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("customerSearch", customerSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

watch([() => props.customers, () => props.loading], ([customers, loading]) => {
  if (loading === false && customers.length > 0) {
    nextTick(() => {
      if (route.query?.focusResult) {
        focusSearchResult(route.query?.focusResult ?? "");
      }
    });
  }
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const currentIconIndex = ref(0);
const defaultSortField = ref(customerSearchColumns.find((column) => column.field === CustomerLinesColumns.Name));
const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    emit("update:sortOrder", -defaultSortOrder.value);

    if (defaultSortField.value) {
      emit("update:sortField", defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();
    defaultSortOrder.value = event.sortOrder === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = customerSearchColumns.find((column) => column.field === event.sortField);

    emit("update:sortOrder", event.sortOrder ?? 0);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const addGlobalSearchClass = (data: SearchCustomer) => {
  if (!data?.id) return ["c-search-result-row"];

  return [
    `c-search-result-row c-sr-id-${data?.id}`,
    { "c-search-result-row-selected": selectedCustomer.value?.id === data?.id },
  ];
};

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? customerSearchColumns
    : customerSearchColumns.filter(
        (c) =>
          c.field === CustomerLinesColumns.RegisteredDate ||
          c.field === CustomerLinesColumns.CustomerNumber ||
          c.field === CustomerLinesColumns.Name,
      );
};

const openSearchCustomer = (searchCustomer: SearchCustomer) => {
  searchCustomer != null && searchCustomer.id !== emptyUuid
    ? router.push({ name: "customer-edit", params: { id: searchCustomer.id } })
    : toast.add({
        severity: "warn",
        summary: t("customer.toast.open-customer-failed"),
        detail: t("customer.toast.no-customer-selected"),
      });
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  openSearchCustomer(event.data);
};

const onRowDoubleClick = (event: DataTableRowDoubleClickEvent) => {
  const searchCustomer = event.data as SearchCustomer;
  if (searchCustomer != null) {
    openSearchCustomer(searchCustomer);
  }
};

const focusSearchResult = (id: LocationQueryValue[] | string = "") => {
  if (props.customers.length > 0 && customerSearchResult.value) {
    const element = id === "" ? null : customerSearchResult.value.$el.querySelector(`tr.c-sr-id-${id}`);
    if (element) {
      element.focus();
      return;
    }
    customerSearchResult.value.$el.querySelector("tbody tr:first-of-type").focus();
  }
};

const onPage = (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const onCustomerNumberClicked = (customer: SearchCustomer) => {
  selectedCustomer.value = customer;
  openSearchCustomer(customer);
};
</script>

<style scoped lang="scss">
.c-multiselect-wrapper {
  position: absolute;
  top: 0px;
  right: 10px;
  z-index: 9;
}

.c-customer-number-text:hover {
  text-decoration: underline;
}

:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}
</style>
