<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="customer-number"
        data-testid="customer-number"
        v-model="customerNumberComputed"
        class="w-full"
        :invalid="val.$error"
        :fluid="true"
        :maxlength="500"
        :class="{ 'c-invalid': isCustomerNumberInUse }"
        v-debounce:400="checkIfCustomerNumberIsInUse"
        aria-describedby="customer-number-help"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.search.header-number').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label for="customer-number">
        {{ t(`customer.search.header-number`) }}
      </label>
    </FloatLabel>
  </div>
  <small class="p-error" v-if="val.$error" data-testid="customer-number-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useCustomerStore } from "@/stores/CustomerStore";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  customerNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:customerNumber", value: string): void;
  (event: "blur", value: string): void;
}>();

const { checkCustomerNumberUsed } = useCustomerStore();
const isCustomerNumberInUse = ref<boolean>(false);
const isChecking = ref<boolean>(false);

const customerNumberComputed = computed<string>({
  get: () => {
    return props.customerNumber ?? "";
  },
  set: (value) => {
    emit("update:customerNumber", value);
  },
});

const { t } = useI18n();

const checkIfCustomerNumberIsInUse = async () => {
  if (customerNumberComputed.value === "") {
    isCustomerNumberInUse.value = false;
    return;
  }
  isChecking.value = true;
  isCustomerNumberInUse.value = await checkCustomerNumberUsed(customerNumberComputed.value);
  isChecking.value = false;
};

const rules = {
  isCustomerNumberInUse: {
    customerNumberAvailable: helpers.withMessage(
      t("validations.customer-number-already-in-use"),
      (value: boolean) => !value
    ),
  },
};

const val = useVuelidate(rules, { isCustomerNumberInUse });
</script>
