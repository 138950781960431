import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { customerGroupApi } from "@/repositories/customer-group/CustomerGroupApi";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { computed, ref } from "vue";
import { useErrorHandler } from "@/repositories/ErrorHandler";

export const useCustomerGroupStore = defineStore("customerGroups", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const customerGroups = ref<CustomerGroup[]>([]);
  const loading = ref(false);
  const saving = ref(false);

  const usePaginator = computed<boolean>(() => {
    return customerGroups.value.length > 10;
  });

  const pages = computed<number>(() => {
    return Math.ceil(customerGroups.value.length / 10);
  });

  const customerGroupNameById = (id: string): string => {
    return customerGroups.value.find((customerGroup) => customerGroup.id === id)?.name ?? "";
  };

  const fetchCustomerGroups = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      customerGroups.value = await customerGroupApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
    } finally {
      loading.value = false;
    }
  };

  const createCustomerGroup = async (customerGroup: CustomerGroup): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await customerGroupApi.create(authHeaders, customerGroup);
      customerGroups.value.push(customerGroup);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const updateCustomerGroup = async (customerGroup: CustomerGroup): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await customerGroupApi.update(authHeaders, customerGroup);
      const index = customerGroups.value.findIndex((cg) => cg.id === customerGroup.id);
      customerGroups.value[index] = customerGroup;
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  const deleteCustomerGroup = async (customerGroup: CustomerGroup): Promise<void> => {
    try {
      saving.value = true;
      const authHeaders = await getAuthHeaders();
      await customerGroupApi.delete(authHeaders, customerGroup.id);
      customerGroups.value = customerGroups.value.filter((cg) => cg.id !== customerGroup.id);
    } catch (error) {
      await handleError(error);
    } finally {
      saving.value = false;
    }
  };

  return {
    customerGroups,
    loading,
    saving,
    usePaginator,
    pages,
    customerGroupNameById,
    fetchCustomerGroups,
    createCustomerGroup,
    updateCustomerGroup,
    deleteCustomerGroup,
  };
});
