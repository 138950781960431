class DocumentLanguageApi {
  constructor() {
    //TODO: Implement DocumentLanguage API
    // const uri = typeof import.meta.env.VITE_APP_API_DOCUMENTLANGUAGE != "undefined" && import.meta.env.VITE_APP_API_DOCUMENTLANGUAGE
    //    ? import.meta.env.VITE_APP_API_DOCUMENTLANGUAGE
    //    : `${import.meta.env.VITE_APP_GATEWAY_URL as string}/documentlanguage`;
    // this.api = httpClient(uri, Authentication.getauthHeaders);
  }

  public getAll(): Promise<string[]> {
    //TODO: Implement GET API
    //return this.api.get("/documentLanguage");

    //TODO: Remove MOCK code!
    return new Promise<string[]>((resolve) => {
      const response = ["NO", "EN", "DK", "FI", "SE", "DE", "IT", "FR"];

      resolve(response);
    });
    //TODO: END MOCK code!
  }
}

const documentLanguageApi = new DocumentLanguageApi();
export { documentLanguageApi };
