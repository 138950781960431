import { type Currency } from "./model/Currency";

class CurrencyApi {
  constructor() {
    //TODO: Implement Currency API
    // const uri = typeof import.meta.env.VITE_APP_API_CURRENCY != "undefined" && import.meta.env.VITE_APP_API_CURRENCY
    //    ? import.meta.env.VITE_APP_API_CURRENCY
    //    : `${import.meta.env.VITE_APP_API_URL as string}/currency`;
    // this.api = httpClient(uri, Authentication.getauthHeaders);
  }

  public getAll(): Promise<Currency[]> {
    //TODO: Implement GET API
    //return this.api.get("/customers");

    //TODO: Remove MOCK code!
    const promise = new Promise<Currency[]>((resolve) => {
      const response = [
        { name: "NOK", value: 0 },
        { name: "SEK", value: 1 },
        { name: "EUR", value: 2 },
        { name: "AUD", value: 3 },
        { name: "BRL", value: 4 },
        { name: "CNY", value: 5 },
        { name: "EGP", value: 6 },
        { name: "INR", value: 7 },
        { name: "JPY", value: 8 },
        { name: "USD", value: 9 },
        { name: "CAD", value: 10 },
      ] as Currency[];

      resolve(response);
    });

    return promise;
    //TODO: END MOCK code!
  }
}

const currencyApi = new CurrencyApi();
export { currencyApi };
