<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <Select
        id="customer-type"
        :options="options"
        optionLabel="name"
        optionValue="value"
        data-testid="customer-type"
        v-model="isBusinessCustomerComputed"
        class="w-full"
        :fluid="true"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('customer.customer-type').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />

      <label for="customer-type">
        {{ t(`customer.customer-type`) }}
      </label>
    </FloatLabel>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CustomerType } from "@/models/customer/CustomerType";

const { t } = useI18n();

const props = defineProps<{
  customerType: CustomerType;
}>();

const emit = defineEmits<{
  (e: "update:customerType", value: string): void;
}>();

const isBusinessCustomerComputed = computed<string>({
  get: () => {
    return props.customerType ?? CustomerType.Business;
  },
  set: (value) => {
    emit("update:customerType", value);
  },
});

const options = ref<Record<string, string>[]>([]);
const fetchCustomerTypes = () => {
  for (const type in CustomerType) {
    options.value.push({
      name: t(`customer.type.${type.toString().toLowerCase()}`),
      value: type,
    });
  }
};

onMounted(fetchCustomerTypes);
</script>
