import { Address } from "@/models/customer/Address";
import { BusinessCustomer } from "@/models/customer/BusinessCustomer";
import { CustomerType } from "@/models/customer/CustomerType";

export class SearchCustomer {
  id = "";
  companyId = "";
  clientId = "";
  customerNumber = "";
  name = "";
  email = "";
  phoneNumber = "";
  address = new Address();
  deliveryAddress = new Address();
  customerGroupIds: string[] = [];
  customerType: CustomerType = CustomerType.Business;
  businessCustomer: BusinessCustomer = new BusinessCustomer();
  registeredDate = "";
}
