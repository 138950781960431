export const CustomerLinesColumns = {
  RegisteredDate: "registeredDate",
  CustomerNumber: "customerNumber",
  CustomerType: "customerType",
  Name: "name",
  PhoneNumber: "phoneNumber",
  Email: "email",
  DeliveryAddressLine1: "deliveryAddressLine1",
  DeliveryAddressCity: "deliveryAddressCity",
  AddressCountry: "addressCountry",
  AddressLine1: "addressLine1",
  AddressCity: "addressCity",
  FirstContactName: "firstContactName",
  FirstContactEmail: "firstContactEmail",
  FirstContactPhone: "firstContactPhone",
  CustomerGroups: "customerGroups",
} as const;
