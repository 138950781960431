<template>
  <div class="mt-4">
    <FloatLabel variant="on">
      <InputText
        id="customer-email"
        data-testid="customer-email"
        v-model="emailComputed"
        class="w-full"
        :invalid="val.$error"
        :fluid="true"
        :maxlength="500"
        aria-describedby="customer-email-help"
        v-tooltip.bottom="{
          value: t('placeholder.type', { property: t('customer.email').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label for="customer-email" class="c-required">
        {{ t(`customer.email`) }}
      </label>
    </FloatLabel>
  </div>
  <small class="p-error" v-if="val.$error" data-testid="customer-email-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { required } from "@/locales/i18n-validators";

const props = defineProps<{
  emailAddress: string;
}>();

const emit = defineEmits<{
  (e: "update:emailAddress", value: string): void;
}>();

const { t } = useI18n();

const emailComputed = computed<string>({
  get: () => {
    return props.emailAddress ?? "";
  },
  set: (value) => {
    emit("update:emailAddress", value);
  },
});

const rules = {
  emailAddress: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
