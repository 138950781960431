<template>
  <div class="c-float-input-container c-minimal-inputnumber text-left mt-1">
    <label for="credit-limit" class="c-floating-label ml-2"> {{ t("customer.credit-limit") }} </label>

    <InputNumber
      id="credit-limit"
      v-model="creditLimitComputed"
      :maxFractionDigits="0"
      data-testid="credit-limit"
      class="w-full text-left"
      :min="0"
    />
  </div>
</template>
<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  creditLimit: number;
}>();

const emit = defineEmits<{
  (e: "update:creditLimit", value: number): void;
}>();
const creditLimitComputed = computed<number>({
  get: () => {
    return props.creditLimit ?? 0;
  },
  set: (value) => {
    emit("update:creditLimit", value);
  },
});
</script>
<style scoped lang="scss">
:deep(.p-inputnumber > .p-inputtext) {
  border-radius: 0;
  text-align: right;
  padding-top: 0.15rem;
  padding-bottom: 0.1rem;
  font-size: 1rem;
  font-weight: normal;
  outline: none;
  border: none;
  border-bottom: var(--floating-input-border);
  &:focus-within {
    border-bottom: var(--floating-input-border-on-focus);
    box-shadow: none;
    border-width: 1px;
  }
}
</style>
