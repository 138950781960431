<template>
  <div class="mt-4">
    <FloatLabelDropdown
      id="customer-currency"
      dataTestId="customer-currency"
      :placeholder="t('placeholder.type', { property: t('customer.currency').toLowerCase() })"
      :label="t(`customer.currency`)"
      v-model:value="selectedCurrency"
      :options="transformedCurrencies"
      :isRequired="true"
      :isInvalid="val.currency.$error"
      :isFloatingLabel="isFloatingLabel"
    />
  </div>
  <small id="customer-currency-help" data-testid="customer-currency-error" class="p-error" v-if="val.$error">{{
    val.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { currencyApi } from "@/repositories/currency/CurrencyApi";
import { Currency } from "@/repositories/currency/model/Currency";
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  currency: string;
}>();

const emit = defineEmits<{
  (e: "update:currency", value: string): void;
}>();

const selectedCurrency = computed<string>({
  get: () => {
    return props.currency ?? "";
  },
  set: (value) => {
    emit("update:currency", value);
  },
});

const currencies = ref<Currency[]>([]);
const fetchCurrencies = async () => {
  currencies.value = await currencyApi.getAll();
};
onMounted(fetchCurrencies);

const rules = {
  currency: {
    required,
  },
};

const val = useVuelidate(rules, props);

const transformedCurrencies = computed(() =>
  currencies.value.map((currency) => ({
    value: currency.name,
    name: currency.name,
  }))
);

const isFloatingLabel = computed(() => {
  return selectedCurrency.value !== "";
});
</script>
