<template>
  <DataTable
    ref="customerGroupTableRef"
    :value="customerGroups"
    scrollHeight="flex"
    dataKey="id"
    v-model:selection="selectedCustomerGroupsComputed"
    :loading="loading"
    :rowHover="true"
    v-model:filters="filtersCustomerGroup"
    data-testid="customer-group-list"
    sortField="name"
    selectionMode="multiple"
    :metaKeySelection="false"
    :sortOrder="1"
    stripedRows
    autoLayout
    :paginator="usePaginator"
    :rows="10"
    class="c-datatable"
  >
    <template #header>
      <span class="p-inputgroup">
        <InputText
          ref="searchInputRef"
          v-model="filtersCustomerGroup['global'].value"
          :placeholder="t('customer.customer-group.search')"
          @keyup.enter="searchInputEnterKey"
          @keypress.tab="focusCustomerGroupTable"
          @keydown.down="focusCustomerGroupTable"
          class="w-full"
          data-testid="customer-group-search"
        />
        <span class="p-button p-inputgroup-addon bg-transparent border-none z-10 -ml-12"
          ><i class="pi pi-search text-zinc-500"
        /></span>
      </span>
    </template>

    <Column selectionMode="multiple" class="c-select-row" />
    <Column field="name" :header="t('common.name')">
      <template #body="{ data }">
        <span data-testid="customer-group-row">{{ data.name }}</span>
      </template>
    </Column>
    <Column field="description" :header="t('common.description')">
      <template #body="{ data }">
        <span data-testid="customer-group-row">{{ data.description }}</span>
      </template>
    </Column>

    <Column class="w-4">
      <template #body="{ data }">
        <PrimeButton
          type="button"
          icon="pi pi-pencil"
          class="p-button-text"
          id="customer-group-edit"
          data-testid="customer-group-edit"
          @click="editCustomerGroup(data)"
        ></PrimeButton>
      </template>
    </Column>

    <template #loading>{{ t("customer.customer-group.loading") }}</template>

    <template #empty>
      <div id="empty-cg-filter" ref="emptyFilterRef">
        {{ t("customer.customer-group.not-found") }}
      </div></template
    >
  </DataTable>
</template>

<script setup lang="ts">
import { ref, nextTick, computed } from "vue";
import { useI18n } from "vue-i18n";
import { FilterMatchMode, FilterOperator } from "@primevue/core/api";
import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import { storeToRefs } from "pinia";

const { t } = useI18n();

const customerGroupStore = useCustomerGroupStore();
const { customerGroups, loading, usePaginator } = storeToRefs(customerGroupStore);

const props = defineProps<{
  selectedCustomerGroups: string[];
}>();

const emit = defineEmits<{
  (e: "createNewCustomerGroup", value: CustomerGroup): void;
  (e: "editCustomerGroup", value: CustomerGroup): void;
  (e: "hideOverlayPanel", value: void): void;
  (e: "update:selectedCustomerGroups", value: string[]): void;
}>();

const filtersCustomerGroup = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
  name: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
  description: { operator: FilterOperator.AND, constraints: [{ value: "", matchMode: FilterMatchMode.STARTS_WITH }] },
});

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const customerGroupTableRef = ref();
const focusCustomerGroupTable = (event: Event) => {
  event.stopPropagation();
  customerGroupTableRef.value.$el.querySelector("tbody tr:first-of-type").focus();
};

const selectedCustomerGroupsComputed = computed<CustomerGroup[]>({
  get: () => {
    return customerGroups.value.filter((cg) => props.selectedCustomerGroups.includes(cg.id));
  },
  set: (value) => {
    const selected = value.map((cg) => cg.id);
    emit("update:selectedCustomerGroups", selected);
  },
});

const emptyFilterRef = ref(null);
const searchInputEnterKey = (event: Event) => {
  event.stopPropagation();
  if (emptyFilterRef.value === null) {
    focusCustomerGroupTable(event);
  } else {
    const cg = new CustomerGroup();
    cg.name = filtersCustomerGroup.value["global"].value;
    emit("createNewCustomerGroup", cg);
  }
};

const editCustomerGroup = (cg: CustomerGroup) => {
  emit("editCustomerGroup", cg);
};
</script>
