<template>
  <Popover
    ref="op"
    data-testid="product-sort-list"
    @show="emit('sortingPopoverVisible', true)"
    @hide="emit('sortingPopoverVisible', false)"
  >
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in searchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="emit('onSelectedRow', column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ column.header }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>

  <Popover
    ref="chooserRef"
    data-testid="product-column-list"
    @show="emit('columnChooserVisible', true)"
    @hide="emit('columnChooserVisible', false)"
  >
    <DataTable
      v-model:selection="selectedColumnsComputed"
      :value="filteredColumns"
      dataKey="field"
      tableStyle="min-width: 16rem"
      responsiveLayout="stack"
      breakpoint="991px"
      selectionMode="multiple"
      :metaKeySelection="false"
      :selectAll="selectAll"
      @select-all-change="emit('onSelectAllChange', $event)"
      @row-select="emit('onColumnSelected', $event.data)"
      @row-unselect="emit('onColumnUnselected', $event.data)"
    >
      <div class="card flex justify-center">
        <div class="flex flex-col gap-4">
          <Column selectionMode="multiple" />
          <Column field="header">
            <template #body="{ data }">
              <span data-testid="column-value-row">{{ data.header }}</span>
            </template>
          </Column>
        </div>
      </div>
    </DataTable>
    <div class="flex justify-end">
      <Button
        type="button"
        :label="t('common.reset')"
        class="w-full m-4"
        severity="danger"
        text
        data-testid="reset-column-button"
        autoOptionFocus="false"
        :tabindex="-1"
        @click="emit('resetColumns')"
      ></Button>
    </div>
  </Popover>
</template>
<script setup lang="ts">
import { ref } from "vue";
import type { DataTableColumn, DataTableColumnExt } from "@cumulus/components";
import { type DataTableSelectAllChangeEvent } from "primevue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  searchColumns: DataTableColumn[];
  defaultSortField: DataTableColumn | undefined;
}>();

const selectedIcon = defineModel<string>("selectedIcon", { required: true, default: "pi pi-sort-amount-up" });
const selectedColumnsComputed = defineModel<DataTableColumn[] | DataTableColumnExt[]>("selectedColumnsComputed", {
  required: true,
});
const filteredColumns = defineModel<DataTableColumnExt[]>("filteredColumns", { required: true });
const selectAll = defineModel<boolean>("selectAll", { required: true, default: false });

const emit = defineEmits<{
  (e: "onSelectedRow", value: DataTableColumn): void;
  (e: "onSelectAllChange", value: DataTableSelectAllChangeEvent): void;
  (e: "onColumnSelected", value: DataTableColumnExt): void;
  (e: "onColumnUnselected", value: DataTableColumnExt): void;
  (e: "resetColumns", value: void): void;
  (e: "sortingPopoverVisible", value: boolean): void;
  (e: "columnChooserVisible", value: boolean): void;
}>();

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (props.defaultSortField?.field ?? "");
};

const chooserRef = ref();
const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const op = ref();
const openSortList = (event: Event) => {
  op.value.toggle(event);
};

defineExpose({
  openColumnChooser,
  openSortList,
});
</script>

<style scoped lang="scss"></style>
