<template>
  <FloatLabel
    ref="inputRef"
    :id="id ?? 'defaultId'"
    :value="props.modelValue ?? ''"
    type="tel"
    data-testid="supplier-phone-number"
    class="inputfield w-full"
    :placeholder="placeholderComputed"
    :selectAllOnFocus="true"
    :isRequired="false"
    maxlength="50"
    :label="labelComputed"
    @keydown="validateKey($event)"
    @focus="selectAllOnFocus"
    aria-describedby="supplier-phone-number-help"
    @input="handleInput"
  />
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  id: string;
  label?: string;
  labelClass?: string;
  hideLabel?: boolean;
  modelValue: string;
  placeholder?: string;
  cssClass?: string;
}>();

const emit = defineEmits<{
  (e: "update:modelValue", modelValue: string): void;
}>();

const { t } = useI18n();

const labelComputed = computed(() => props.label ?? t(`common.phone-label`));
const placeholderComputed = computed(() => props.placeholder ?? t(`common.phone-placeholder`));

const selectAllOnFocus = (event: FocusEvent) => {
  (event.target as HTMLInputElement).select();
};

const inputRef = ref<HTMLInputElement>();
const focus = () => {
  if (inputRef.value) {
    inputRef.value.focus();
  }
};

defineExpose({
  focus,
});

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const sanitizedValue = sanitizePhoneNumberInput(target.value);
  emit("update:modelValue", sanitizedValue);
};

const sanitizePhoneNumberInput = (input: string): string => {
  // Replace any non-numeric, non '+' or '-' characters with empty string
  return input.replace(/[^0-9+-]/g, "");
};

const validateKey = (event: KeyboardEvent) => {
  // Allow numeric keys, backspace, delete, +, -
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "-",
    "+",
    "Backspace",
    "Delete",
    "ArrowLeft",
    "ArrowRight",
    "Home",
    "End",
    "Tab",
  ];

  if (!allowedKeys.includes(event.key)) {
    event.preventDefault();
  }
};
</script>
