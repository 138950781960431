<template>
  <InputText
    :modelValue="props.email"
    class="w-full"
    id="contact-email"
    data-testid="contact-email"
    :placeholder="t('placeholder.type', { property: t('customer.contact-list.email').toLowerCase() })"
    :class="{ 'p-invalid': val.$error }"
    @update:modelValue="emit('update:email', $event as string)"
  />
  <small class="p-error" v-if="val.$error" data-testid="contact-email-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { Contact } from "@/models/customer/Contact";
import { email as validateEmail, required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  email: string;
  contact: Contact;
  contacts: Contact[];
}>();

const emit = defineEmits<{
  (e: "update:email", value: string): void;
}>();

const isUniqueEmail = (email: string) => {
  if (email.trim().length === 0) return true;
  const duplicateContact = props.contacts.find(
    (c) => c.email.toLowerCase() === email.toLowerCase() && c.id !== props.contact.id
  );
  return duplicateContact === undefined;
};
const rules = {
  email: {
    required,
    validateEmail: helpers.withMessage(t("validations.email"), validateEmail),
    unique: helpers.withMessage(t("common.validation.email-exist"), isUniqueEmail),
  },
};

const val = useVuelidate(rules, props);
</script>
@/locales/i18n-validators
