<template>
  <div class="mt-4">
    <FloatLabelDropdown
      dataTestId="default-freight-method"
      :label="t('customer.freight-method')"
      :placeholder="t('placeholder.select', { property: t('customer.freight-method').toLowerCase() })"
      v-model:value="selectedFreightMethodId"
      :options="transformedFreightMethods"
      :isFloatingLabel="isFloatingLabel"
    />
  </div>
</template>

<script setup lang="ts">
import { FreightMethod } from "@/repositories/freight-method/model/FreightMethod";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { NIL as emptyUuid } from "uuid";

const { t } = useI18n();
const props = defineProps<{
  defaultFreightMethod: string;
  freightMethods: FreightMethod[];
}>();

const emit = defineEmits<{
  (e: "update:defaultFreightMethod", value: string): void;
}>();

const selectedFreightMethodId = computed<string>({
  get: () => {
    return props.defaultFreightMethod ?? "";
  },
  set: (value) => {
    emit("update:defaultFreightMethod", value);
  },
});

const transformedFreightMethods = computed(() =>
  props.freightMethods.map((method) => ({
    value: method.id,
    name: method.name,
  }))
);

const isFloatingLabel = computed(() => {
  return selectedFreightMethodId.value !== emptyUuid;
});
</script>

<style lang="scss">
.w-full .p-inputtext {
  width: 100%;
}
</style>
