import { NewCustomer } from "@/models/customer/NewCustomer";
import { Address } from "@/models/customer/Address";
import type { Ref } from "vue";
import cloneDeep from "lodash.clonedeep";

export function customerAddressFunctions(customer: Ref<NewCustomer>) {
  const setCompanyAddress = (value: Address) => {
    const address = cloneDeep(value);
    customer.value.address = address;

    if (!addressComplete(customer.value.address)) return;

    if (addressEmpty(customer.value.deliveryAddress)) {
      const address = cloneDeep(value);
      setDeliveryAddress(address);
    }
  };

  const setDeliveryAddress = (value: Address) => {
    const address = cloneDeep(value);
    customer.value.deliveryAddress = address;

    if (!addressComplete(customer.value.deliveryAddress)) return;

    if (addressEmpty(customer.value.address)) {
      const address = cloneDeep(value);
      setCompanyAddress(address);
    }
  };

  const addressComplete = (address: Address) => {
    return (
      address.addressLines.length > 0 &&
      address.postalCode.length > 0 &&
      address.city.length > 0 &&
      address.countryIso.length > 0
    );
  };

  const addressEmpty = (address: Address) => {
    return (
      (address.addressLines.length === 0 && address.postalCode.length === 0 && address.city.length === 0) ||
      address.countryIso.length === 0
    );
  };

  return {
    setCompanyAddress,
    setDeliveryAddress,
  };
}
