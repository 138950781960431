import { useAuth } from "@cumulus/event-bus";
import { paymentTermApi } from "./PaymentTermApi";
import { PaymentTerm } from "./model/PaymentTerm";
import { useErrorHandler } from "../ErrorHandler";

export function usePaymentTerm() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllPaymentTerms = async (): Promise<PaymentTerm[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await paymentTermApi.getPaymentTermsForCustomers(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllPaymentTerms };
}
