import { defineStore } from "pinia";
import { useAuth } from "@cumulus/event-bus";
import { customerApi } from "../api/customer/CustomerApi";
import { Customer } from "@/models/customer/Customer";
import { useErrorHandler } from "@/repositories/ErrorHandler";
import { NewCustomer } from "@/models/customer/NewCustomer";
import { CustomerType } from "@/models/customer/CustomerType";
import { BusinessCustomer } from "@/models/customer/BusinessCustomer";
import { PrivateCustomer } from "@/models/customer/PrivateCustomer";
import cloneDeep from "lodash.clonedeep";

export const useCustomerStore = defineStore("useCustomerStore", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  let customerCache: Customer | null = null;
  let isLoadingCustomerId: string | null = null;

  const timeout = (ms: number) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const getCustomer = async (id: string): Promise<Customer> => {
    try {
      if (isLoadingCustomerId === id) {
        await timeout(50); // Wait for 50ms to to avoid multiple requests for the same customer

        if (isLoadingCustomerId !== null) {
          return await getCustomer(id);
        }
      }

      if (customerCache !== null && customerCache.id === id) {
        return customerCache;
      }

      isLoadingCustomerId = id;

      const authHeaders = await getAuthHeaders();

      const customer = await customerApi.get(authHeaders, id);
      if (customer.businessCustomer === null) {
        customer.businessCustomer = new BusinessCustomer();
      }
      if (customer.privateCustomer === null) {
        customer.privateCustomer = new PrivateCustomer();
      }

      customerCache = customer;
      return customer;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      isLoadingCustomerId = null;
    }
  };

  const createCustomer = async (customer: NewCustomer): Promise<string> => {
    try {
      const newCustomer = cloneDeep(customer);
      if (newCustomer.customerType === CustomerType.Business) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newCustomer.privateCustomer = null;
      } else if (newCustomer.customerType === CustomerType.Private) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newCustomer.businessCustomer = null;
      }
      const authHeaders = await getAuthHeaders();
      return await customerApi.create(authHeaders, newCustomer);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCustomer = async (customer: Customer): Promise<void> => {
    try {
      const updateCustomer = cloneDeep(customer);
      if (updateCustomer.customerType === CustomerType.Business) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateCustomer.privateCustomer = null;
      } else if (updateCustomer.customerType === CustomerType.Private) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateCustomer.businessCustomer = null;
      }
      const authHeaders = await getAuthHeaders();
      return await customerApi.update(authHeaders, updateCustomer);
    } catch (error) {
      await handleError(error);
    }
  };

  const deleteCustomer = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await customerApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
    }
  };

  const checkCustomerNumberUsed = async (customerNumber: string): Promise<boolean> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await customerApi.checkCustomerNumberUsed(authHeaders, customerNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getCustomer, createCustomer, updateCustomer, deleteCustomer, checkCustomerNumberUsed };
});
