<template>
  <div class="mb-4 mr-8">
    <FloatLabel variant="on">
      <InputText
        :id="`${addressType}-address-line`"
        v-model="addressLine1Computed"
        class="w-full"
        :invalid="val.addressLines.$error"
        @change="onChangeComplete"
        :data-testid="`${addressType}-address-line-1`"
        v-tooltip.bottom="{
          value: t('common.address.address-lines.placeholder'),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label class="c-required">{{ addressLabel }} </label>
    </FloatLabel>
    <small class="p-error" v-if="val.addressLines.$error" :id="`${addressType}-address-lines-error`">
      {{ val.addressLines.$errors[0].$message }}
    </small>
  </div>

  <div class="mb-4 mr-8">
    <FloatLabel variant="on">
      <InputText v-model="addressLine2Computed" :data-testid="`${addressType}-address-line-2`" class="w-full" />
    </FloatLabel>
  </div>

  <div class="mb-4 mr-8">
    <FloatLabel variant="on">
      <InputText v-model="addressLine3Computed" :data-testid="`${addressType}-address-line-3`" class="w-full" />
    </FloatLabel>
  </div>

  <div class="mb-4 mr-8">
    <FloatLabel variant="on">
      <Select
        :id="`${addressType}-country`"
        :options="transformedCountryOptions"
        optionLabel="name"
        optionValue="value"
        v-model="selectedCountryComputed"
        class="inputfield w-full"
        :invalid="val.countryIso.$error"
        @change="onChangeComplete"
        :data-testid="`${addressType}-country`"
        :pt:list:data-testid="`${addressType}-country-list`"
        v-tooltip.bottom="{
          value: t('placeholder.select', { property: t('common.address.country').toLowerCase() }),
          showDelay: 1000,
          hideDelay: 300,
        }"
      />
      <label :for="`${addressType}-country`">{{ t("common.address.country") }}</label>
    </FloatLabel>
    <small class="p-error" v-if="val.countryIso.$error" :id="`${addressType}-country-error`">{{
      val.countryIso.$errors[0].$message
    }}</small>
  </div>

  <div class="grid grid-cols-12 gap-4 mr-8">
    <div class="col-span-4 mr-4">
      <FloatLabel variant="on">
        <InputText
          :id="`${addressType}-postal-code`"
          :data-testid="`${addressType}-postal-code`"
          v-model="addressComputed.postalCode"
          maxlength="10"
          :invalid="val.postalCode.$error"
          class="w-full"
          @change="onPostalCodeChange(($event.target as HTMLInputElement).value)"
          v-tooltip.bottom="{
            value: t('placeholder.type', { property: t('common.address.postal-code').toLowerCase() }),
            showDelay: 1000,
            hideDelay: 300,
          }"
        />
        <label class="c-required">{{ t("common.address.postal-code") }}</label>
      </FloatLabel>
      <small class="p-error" v-if="val.postalCode.$error" :id="`${addressType}-postal-code-error`">{{
        val.postalCode.$errors[0].$message
      }}</small>
    </div>

    <div class="col-span-8">
      <FloatLabel variant="on">
        <InputText
          :id="`${addressType}-city`"
          :data-testid="`${addressType}-city`"
          v-model="addressComputed.city"
          class="w-full"
          :invalid="val.city.$error"
          @change="onChangeComplete"
          v-tooltip.bottom="{
            value: t('placeholder.type', { property: t('common.address.city').toLowerCase() }),
            showDelay: 1000,
          }"
        />

        <label class="c-required">{{ t("common.address.city") }}</label>
      </FloatLabel>

      <small class="p-error" v-if="val.city.$error" :id="`${addressType}-city-error`">{{
        val.city.$errors[0].$message
      }}</small>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Address } from "@/models/customer/Address";
import type { Country } from "@/models/country/Country";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { useCountry } from "@/api/country/CountryService";
import { helpers } from "@vuelidate/validators";
import cloneDeep from "lodash.clonedeep";

const { t } = useI18n();
const { getCity } = useCountry();

const props = defineProps<{
  address: Address;
  countryOptions: Country[];
  addressType: string;
  selectedCountry: string;
  addressLabel: string;
}>();

const emit = defineEmits<{
  (e: "setAddress", value: Address): void;
  (e: "autoSelectCountry", value: string): void;
}>();

const addressComputed = computed<Address>(() => {
  return props.address ? cloneDeep(props.address) : new Address();
});

const addressLine1Computed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines[0];
  },
  set: (val) => {
    addressComputed.value.addressLines[0] = val;
    onChangeComplete();
  },
});
const addressLine2Computed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines[1];
  },
  set: (val) => {
    addressComputed.value.addressLines[1] = val;
    onChangeComplete();
  },
});
const addressLine3Computed = computed<string>({
  get: () => {
    return addressComputed.value.addressLines[2];
  },
  set: (val) => {
    addressComputed.value.addressLines[2] = val;
    onChangeComplete();
  },
});

const selectedCountryComputed = computed<string>({
  get: () => {
    if (props.selectedCountry) {
      emit("autoSelectCountry", props.selectedCountry);
    }
    return addressComputed.value.countryIso;
  },
  set: (value: string) => {
    addressComputed.value.countryIso = value;
    onChangeComplete();
  },
});

const onChangeComplete = () => {
  emit("setAddress", addressComputed.value);
};

const onPostalCodeChange = async (code: string) => {
  if (code.trim() === "") {
    return;
  }

  const response = await getCity(code);

  if (response.length > 0) {
    addressComputed.value.city = response[0].city;
  }

  onChangeComplete();
};

const rules = {
  addressLines: {
    required,
    $each: helpers.withMessage(t("common.address.address-lines.error"), (value: string[]) => {
      return value !== undefined && value[0].length > 1;
    }),
  },
  postalCode: {
    required,
  },
  city: {
    required,
  },
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, addressComputed);

const transformedCountryOptions = computed(() =>
  props.countryOptions.map((country) => ({
    value: country.iso,
    name: country.name,
  })),
);
</script>
