import { useAuth } from "@cumulus/event-bus";
import { freightMethodApi } from "./FreightMethodApi";
import { useErrorHandler } from "../ErrorHandler";
import type { FreightMethod } from "./model/FreightMethod";

export function useFreightMethod() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllFreightMethods = async (): Promise<FreightMethod[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await freightMethodApi.getFreightMethodsForCustomer(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getAllFreightMethods };
}
