<template>
  <div class="flex">
    <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.customer-group") }}</div>
    <div class="w-3/5">
      <div
        v-for="customerGroup in customer?.customerGroupIds"
        :key="customerGroup"
        class="flex justify-between items-center"
      >
        <span>{{ customerGroupNameById(customerGroup) }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { Customer } from "@/models/customer/Customer";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import { onMounted } from "vue";

const customerGroupStore = useCustomerGroupStore();
const customerGroupNameById = customerGroupStore.customerGroupNameById;

const { t } = useI18n();
const customer = defineModel<Customer | null>("customer", { required: true });

onMounted(() => {
  customerGroupStore.fetchCustomerGroups();
});
</script>
