<template>
  <div class="mb-2" data-testid="customer-groups">
    <div
      v-for="(customerGroup, index) in selectedCustomerGroups"
      :key="customerGroup"
      class="flex justify-between items-center"
    >
      <span>{{ customerGroupNameById(customerGroup) }}</span>

      <Button
        :data-testid="'customer-group-remove-' + index"
        icon="pi pi-trash"
        class="p-button-rounded p-button-secondary p-button-text"
        @click="onConfirmDelete($event, customerGroup)"
      />
    </div>

    <span v-if="selectedCustomerGroups.length === 0">{{ t("customer.customer-group.not-found") }}</span>
  </div>

  <Button
    type="button"
    class="p-button-text p-button-sm"
    data-testid="customer-group-add"
    aria:haspopup="true"
    aria-controls="overlay_panel"
    @click="showCustomerGroupPanel"
    id="add-customer-group-button"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </Button>

  <CustomerGroupPanel ref="customerGroupPanelRef" v-model:selectedCustomerGroups="selectedCustomerGroups" />
  <ConfirmPopup data-testid="customer-group-remove-confirm"></ConfirmPopup>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useCustomerGroupStore } from "@/repositories/customer-group/CustomerGroupStore";
import CustomerGroupPanel from "./CustomerGroupPanel.vue";
import { useConfirm } from "primevue/useconfirm";

const confirm = useConfirm();
const { t } = useI18n();
const customerGroupStore = useCustomerGroupStore();
const customerGroupNameById = customerGroupStore.customerGroupNameById;

const props = defineProps<{
  customerGroups: string[];
}>();

const emit = defineEmits<{
  (e: "update:customerGroups", value: string[]): void;
}>();

const selectedCustomerGroups = computed<string[]>({
  get: () => {
    return props.customerGroups && props.customerGroups.length > 0 ? props.customerGroups : [];
  },
  set: (value) => {
    emit("update:customerGroups", value);
  },
});

const removeCustomerGroup = (id: string) => {
  const customerGroups = selectedCustomerGroups.value.filter((x) => x !== id);
  emit("update:customerGroups", customerGroups);
  document.getElementById("add-customer-group-button")?.focus();
};

const customerGroupPanelRef = ref();
const showCustomerGroupPanel = (event: Event) => {
  customerGroupPanelRef.value.toggle(event);
};

const onConfirmDelete = (event: Event, customerGroupId: string) => {
  const groupName = customerGroupNameById(customerGroupId);
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("common.delete-confirm", { groupName }),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      removeCustomerGroup(customerGroupId);
    },
  });
};

onMounted(() => {
  customerGroupStore.fetchCustomerGroups();
});
</script>
