import { Agreement } from "./Agreement";
import { NIL as emptyUuid, v4 as uuidv4 } from "uuid";

export class FreightAgreement {
  id = uuidv4();
  freightMethodId = emptyUuid;
  agreement = new Agreement();
  agreementPrice = 0;
  startDate = new Date().toJSON();
  endDate = new Date().toJSON();
}
