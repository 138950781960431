import { v4 as uuidv4 } from "uuid";

export class Contact {
  id = uuidv4();
  firstName = "";
  lastName = "";
  email = "";
  phoneNumber = "";
  isInvoiceRecipient = false;
}
