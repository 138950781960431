import { CustomerGroup } from "@/repositories/customer-group/model/CustomerGroup";
import { CustomerType } from "@/models/customer/CustomerType";
import { SearchCustomer } from "./SearchCustomer";

export class ListCustomer {
  id = "";
  customerNumber = "";
  name = "";
  email = "";
  phoneNumber = "";
  addressLine1 = "";
  addressCity = "";
  addressCountry = "";
  deliveryAddressLine1 = "";
  deliveryAddressCity = "";
  customerGroups = "";
  customerType: CustomerType = CustomerType.Business;
  firstContactName = "";
  firstContactEmail = "";
  firstContactPhone = "";
  registeredDate = "";

  constructor(customer: SearchCustomer, customerGroups: CustomerGroup[]) {
    this.id = customer.id;
    this.customerNumber = customer.customerNumber;
    this.name = customer.name;
    this.email = customer.email;
    this.phoneNumber = customer.phoneNumber;
    this.addressLine1 = customer.address.addressLines[0];
    this.addressCity = customer.address.city;
    this.addressCountry = customer.address.countryIso;
    this.deliveryAddressLine1 = customer.deliveryAddress.addressLines[0];
    this.deliveryAddressCity = customer.deliveryAddress.city;
    this.customerType = customer.customerType;
    this.registeredDate = customer.registeredDate;

    if (customer.businessCustomer !== null && customer.businessCustomer.contacts.length > 0) {
      const contact = customer.businessCustomer.contacts[0];
      this.firstContactName = contact.firstName + " " + contact.lastName;
      this.firstContactEmail = contact.email;
      this.firstContactPhone = contact.phoneNumber;
    }

    this.customerGroups = customer.customerGroupIds
      .map((customerGroup) => customerGroups.find((cg) => cg.id === customerGroup)?.name ?? customerGroup)
      .join(", ");
  }
}
