<template>
  <div class="flex mb-2">
    <div class="w-2/5 pr-2 font-bold text-gray-600">{{ t("overview.payment-term") }}</div>
    <div class="w-3/5">{{ paymentTermName }}</div>
  </div>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { usePaymentTerm } from "@/repositories/payment-term/PaymentTermService";
import { computed, onMounted, ref } from "vue";

const { getAllPaymentTerms } = usePaymentTerm();
const paymentTerms = ref();

const { t } = useI18n();
const defaultPaymentTermId = defineModel<string>("defaultPaymentTermId", { required: true });

const getPaymentTerms = async () => {
  paymentTerms.value = await getAllPaymentTerms();
};

const paymentTermName = computed(() => {
  if (!paymentTerms.value) return "";
  const paymentTerm = paymentTerms.value.find((pt: { id: string }) => pt.id === defaultPaymentTermId.value);
  return paymentTerm?.name ?? "";
});

onMounted(() => {
  getPaymentTerms();
});
</script>
