import { CustomerType } from "./CustomerType";
import { Address } from "./Address";
import { CustomerState } from "./CustomerState";
import { Payment } from "./Payment";
import { BusinessCustomer } from "./BusinessCustomer";
import { Freight } from "./Freight";
import { PrivateCustomer } from "./PrivateCustomer";
import { CustomerDocument } from "../CustomerDocument";

export class Customer {
  id = "";
  companyId = "";
  clientId = "";
  customerNumber = "";
  name = ""; // Read only
  email = "";
  phoneNumber = "";
  documentLanguageIso = "";
  documents: CustomerDocument[] = [];
  customerState: CustomerState = CustomerState.Active;
  payment = new Payment();
  freight = new Freight();
  address = new Address();
  deliveryAddress = new Address();
  customerGroupIds: string[] = [];
  registeredDate = "";
  customerType: CustomerType = CustomerType.Private;
  businessCustomer: BusinessCustomer = new BusinessCustomer();
  privateCustomer: PrivateCustomer = new PrivateCustomer();
}
