<template>
  <div>
    <CumulusDatePicker :date="date" :id="id" @update:date="updateValue" />

    <small class="p-error" v-if="isEndDate && val.$error" :data-testid="`${idComputed}-error`">
      {{ val.$errors[0].$message }}
    </small>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { CumulusDatePicker } from "@cumulus/components";

const { t } = useI18n();

const props = defineProps<{
  date: string;
  id?: string;
  isEndDate: boolean;
}>();

const emit = defineEmits<{
  (e: "update:date", value: string): void;
  (e: "updateFreightAgreement"): void;
}>();

const idComputed = computed(() => {
  return props.id ?? "";
});

const dateComputed = computed(() => {
  return dateFormatted(props.date) ?? "";
});

const dateFormatted = (value: string): string => {
  const date = value ? new Date(value) : new Date();
  const yyyy = date.getFullYear();
  const month = date.getMonth() + 1; // Months start at 0 index!
  const day = date.getDate();

  const mm = month < 10 ? "0" + month : month;
  const dd = day < 10 ? "0" + day : day;

  return yyyy + "-" + mm + "-" + dd;
};

const updateValue = (value: string) => {
  emit("update:date", value);
  emit("updateFreightAgreement");
};

const minDate = (value: string) => {
  if (!value) return true;
  return new Date(value) > new Date();
};

const rules = {
  date: props.isEndDate
    ? {
        minDate: helpers.withMessage(t("common.validation.future-end-date"), minDate),
      }
    : {},
};

const val = useVuelidate(rules, { date: dateComputed });
</script>
